



























import { Component, Vue } from 'vue-property-decorator'
import { uiStore } from '@/store'

@Component({ name: 'IntegrationHelp' })
export default class IntegrationHelp extends Vue {
  // @Getter currentModalPayload!: {
  //   data: {
  //     kind: string;
  //     help: string;
  //   };
  // }

  get headerCaption (): string {
    return `${this.$t('modals.IntegrationHelp.help')} - ${this.kind}`
  }

  // TODO: remove any, set type for payload
  get currentModalPayload (): any {
    return uiStore.getters.currentModalPayload
  }

  get kind (): string {
    return this.currentModalPayload.data?.kind
  }

  get help (): string {
    return this.currentModalPayload.data?.help
  }

  close () {
    uiStore.actions.hideModal()
  }
}
